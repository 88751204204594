"use client";
import axios from "axios";
import Image from "next/image";
import { useEffect, useState, useRef } from "react";

const Balance: React.FC = () => {
  const [balance, setBalance] = useState<string>("");
  const fetched = useRef(false); // Flag, um mehrfachen Abruf zu verhindern

  useEffect(() => {
    if (fetched.current) return; // Verhindert mehrfachen Abruf

    async function fetchData() {
      try {
        fetched.current = true; // Markiere Abruf als durchgeführt
        const response = await axios.post("/api/wallet/balance");
        setBalance(response.data.data.balance);
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="flex">
      <p className="text-xs opacity-70">Balance:</p>
      <p className="my-auto text-xs opacity-70 ml-2">{balance}</p>
      <Image
        src="/coin.png"
        className="max-h-[13px] my-auto ml-1"
        height={12}
        width={12}
        alt="Coin Icon"
      />
    </div>
  );
};

export default Balance;
