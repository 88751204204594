"use client";

import Image from "next/image";
import { IoPlaySkipBackOutline, IoPlaySkipForwardOutline, IoShuffle } from "react-icons/io5";
import { TiArrowLoop } from "react-icons/ti";
import { CiPlay1, CiPause1 } from "react-icons/ci";
import { SlVolume2, SlVolumeOff } from "react-icons/sl"; 

import { useAudio } from "./AudioContext";
import { Button } from "@/components/ui/button";
import { FaPause, FaPlay } from "react-icons/fa";
import { BsFileEarmarkMusicFill } from "react-icons/bs";
import Link from "next/link";

export default function AudioPlayer() {
    const {source, togglePlayPause, isPlaying, mute, unmute, isMuted, volume, setVolume, currentTime, duration, handleSeek, title, artist, image, link, artistLink } = useAudio(); 

    const changeRange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTime = parseFloat(e.target.value);
        handleSeek(newTime); // Update current time and seek
    };

    const calculateTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`; // Format time
    };
    if (source !== " "){
    return (
        <div className="w-full h-20 z-20 fixed bottom-0 left-0 bg-company-gray shadow-xl px-8">
            <div className="max-w-[1400px] m-auto">
                <div className="grid grid-cols-3">
                    <div className="flex py-4">
                        {image?
                            <Image className="my-auto rounded-sm" src={image} width={48} height={48} alt="Cover-Picture" />
                            :
                            <BsFileEarmarkMusicFill className="text-4xl my-auto text-white text-opacity-30" />
                        
                        
                            
                        }
                        
                        <div className="px-4 items-center my-auto">
                            <Link href={`/track/${link}`}><p className="text-company-green truncate max-w-[250px] overflow-hidden hover:underline">{title}</p></Link>
                            <Link href={`/profile/${artistLink}`}><p className="text-sm text-white opacity-50 hover:underline">{artist}</p></Link>
                        </div>

                    </div>

                    <div className="flex m-auto">
                        
                            
                            <div className="flex m-auto mr-4 ">
                                
                                <Button  className="p-2 w-8 h-8 bg-white text-black items-center gap-1 rounded-full hover:bg-opacity-90" onClick={togglePlayPause}>
                                   <div className="m-auto flex"> {isPlaying ? <FaPause className="max-w-3" /> : <FaPlay className="max-w-3" />}</div>
                                </Button>
                             
                            </div>
                           
                        
                        
                        <div className="flex text-white m-auto">
                            <div className="text-xs pt-2 opacity-50">{calculateTime(currentTime)}</div>
                            <div className="px-3">
                                <input
                                    type="range"
                                    min="0"
                                    max={duration}
                                    value={currentTime}
                                    className="w-96 mb-4 h-[2px] rounded-lg appearance-none cursor-pointer opacity-50"
                                    onChange={changeRange}
                                />
                            </div>
                            <div className="text-xs pt-2 opacity-50">{calculateTime(duration)}</div>
                        </div> 
                        <Button  className="text-2xl opacity-50 text-white"><TiArrowLoop /></Button>
                    </div>

                    <div className="flex gap-4 justify-end text-white opacity-50">
                        <button className="text-xl" onClick={isMuted ? unmute : mute}>
                            {isMuted ? <SlVolumeOff /> : <SlVolume2 />}
                        </button>
                        <input
                            type="range"
                            className="w-32 my-auto h-[2px] rounded-lg appearance-none cursor-pointer"
                            min="0"
                            max="1"
                            step="0.01"
                            value={isMuted ? 0 : volume}
                            onChange={(e) => setVolume(parseFloat(e.target.value))}
                        />
                    </div>
                </div>
            </div>
        </div>
    );}
}
