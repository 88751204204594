import(/* webpackMode: "eager", webpackExports: ["AudioProvider"] */ "/app/app/components/AudioContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/AudioPlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/FeedbackButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/HeaderMobileLogin.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/HeaderMobileLogout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginDialog"] */ "/app/app/components/LoginDialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/LogoutDialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/Search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/components/SessionWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":\"300\"}],\"variableName\":\"font\"}");
