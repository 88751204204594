"use client";
import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import ReactHowler from "react-howler";

interface AudioContextType {
    isPlaying: boolean;
    togglePlayPause: () => void;
    setSource: (source: string) => void;
    setTitle: (title: string) => void;
    setArtist: (artist: string) => void;
    setImage: (image: string) => void;
    setLink: (link: string) => void;
    setArtistLink: (artistLink: string) => void;
    volume: number;
    setVolume: (volume: number) => void;
    isMuted: boolean;
    mute: () => void;
    unmute: () => void;
    playerRef: React.RefObject<ReactHowler | null>;
    currentTime: number;
    duration: number;
    setDuration: (duration: number) => void;
    handleSeek: (time: number) => void;
    title: string;
    artist: string;
    image: string;
    source: string;
    link: string;
    artistLink: string;
}

const AudioContext = createContext<AudioContextType | undefined>(undefined);

export const useAudio = () => {
    const context = useContext(AudioContext);
    if (!context) {
        throw new Error("useAudio must be used within an AudioProvider");
    }
    return context;
};

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [source, setSource] = useState<string>(" ");
    const [title, setTitle] = useState<string>("");
    const [artist, setArtist] = useState<string>("");
    const [image, setImage] = useState<string>("");
    const [link, setLink] = useState<string>("");
    const [artistLink, setArtistLink] = useState<string>("");
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const playerRef = useRef<ReactHowler | null>(null);

    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };

    const mute = () => {
        setVolume(0);
        setIsMuted(true);
    };

    const unmute = () => {
        setVolume(1);
        setIsMuted(false);
    };

    const handleLoad = () => {
        setCurrentTime(0);
    };

    const handleSeek = (time: number) => {
        setCurrentTime(time);
        if (playerRef.current) {
            playerRef.current.seek(time);
        }
    };

    useEffect(() => {
        // Load saved values from localStorage when the component is mounted
        const savedTitle = localStorage.getItem("title");
        const savedArtist = localStorage.getItem("artist");
        const savedImage = localStorage.getItem("image");
        const savedSource = localStorage.getItem("source");
        const savedLink = localStorage.getItem("link");
        const savedArtistLink = localStorage.getItem("artistLink");
    
        if (savedTitle) setTitle(savedTitle);
        if (savedArtist) setArtist(savedArtist);
        setImage(savedImage ?? ""); // Allow empty string if not set
        if (savedSource) setSource(savedSource);
        if (savedLink) setLink(savedLink);
        if (savedArtistLink) setArtistLink(savedArtistLink)
    }, []);
    
    useEffect(() => {
        // Save title, artist, image, and source to localStorage when they change
        localStorage.setItem("title", title || "");
        localStorage.setItem("artist", artist || "");
        localStorage.setItem("image", image || ""); // Always save, even empty string
        localStorage.setItem("source", source || "");
        localStorage.setItem("link", link || "");
        localStorage.setItem("artistLink", artistLink || "");
    }, [title, artist, image, source, link, artistLink]);
    

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPlaying && playerRef.current) {
                setCurrentTime(playerRef.current.seek());
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isPlaying]);

    const value = {
        isPlaying,
        togglePlayPause,
        setSource,
        setTitle,
        setArtist,
        setImage,
        setLink,
        setArtistLink,
        volume,
        setVolume,
        isMuted,
        mute,
        unmute,
        playerRef,
        currentTime,
        duration,
        setDuration,
        handleSeek,
        title,
        artist,
        image,
        source,
        link,
        artistLink
    };

    return (
        <AudioContext.Provider value={value}>
            <ReactHowler
                src={source}
                playing={isPlaying}
                volume={isMuted ? 0 : volume}
                ref={playerRef}
                onLoad={() => {
                    handleLoad();
                    if (playerRef.current) {
                        const audioDuration = playerRef.current.duration();
                        setDuration(audioDuration);
                    }
                }}
                onEnd={() => {
                    setIsPlaying(false); // Automatically stop playing when the audio ends
                }}
            />
            {children}
        </AudioContext.Provider>
    );
};
