import axios from "axios";


interface Session {
    user: {
        id: string;
    };
}

export async function fetchImage(): Promise<string | null> {
    
    try {
        const response = await axios.post<{ url: string }>("/api/profiles/avatar");
    
        return response.data.url || null;
      } catch (error) {
        console.error("Error fetching image:", error);
        return null;
      }
}
export async function fetchUserImage(id: string) {
    try {
      const response = await axios.post(`/api/profiles/avatar/id`, {
        id,
      });
      if (!response) throw new Error("Failed to fetch image");
  
      
      return response.data.url;
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  }
  

  export async function uploadImage( formData: FormData): Promise<void> {
    try {
      const formDataWithUserId = new FormData();
      formData.forEach((value, key) => formDataWithUserId.append(key, value));
  
      const response = await axios.post('/api/profiles/avatar/update', formDataWithUserId);
  
      if (response.data?.message) {
        // Erfolgreiche Antwort verarbeiten (z. B. UI aktualisieren)
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      // Fehlerbehandlung im UI
    }
  }
  


  export async function uploadTrackImage(id: string, formData: FormData): Promise<void> {
    try {
      // Append the id to formData directly instead of sending as part of JSON
      const formDataWithUserId = new FormData();
      formDataWithUserId.append("id", id);
      formData.forEach((value, key) => formDataWithUserId.append(key, value));

      const response = await axios.post("/api/tracks/images/upload", formDataWithUserId);

      if (response.data?.error) {
        throw new Error(response.data.error);
      }

      // Handle success (e.g., show success notification or update UI)
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
}
