"use client";

import Link from "next/link";
import Image from 'next/image'
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { fetchImage } from "../api/ImageHandling/ImageHandling";
import Balance from "./Balance";
import axios from "axios";
import { AiFillFire } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdPricetag } from "react-icons/io";
import { Button } from "@/components/ui/button";
import { IoIosSettings } from "react-icons/io";

export default function HeaderMobileLogin (){
    
    const [nav, setNav] = useState(false);
    const [username, setUsername] = useState('');
    const [imageUrl, setImageUrl] = useState<string>('');

    const handleNav = () => {
      setNav(!nav);
    };
    
 

    return(

    <header className=' bg-company-gray text-white fixed top-0 w-screen z-10 ease-in duration-300 mb-20'>
        <div className='flex justify-between items-center gap-4 h-16 mx-auto px-4 text-gray-950'>
        
        <Link href="/">
        <Image
          src="/logo.png"
          width={160}
          height={0}
          alt="COYNED"   
        />
        </Link>
        <div onClick={handleNav}>
          {nav ? <AiOutlineClose size={30}/> : <AiOutlineMenu size={30} />}
        </div>
        </div>

        <div>
       
        <ul className={nav ? 'fixed w-screen h-full border-r border-r-company-gray-hover bg-black ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'} onClick={handleNav}>
          <li className='p-6 border-b border-company-gray-hover justify-center m-auto flex' >
            <div className="flex flex-col gap-4">
                 <Link href="/login">
                    <Button className="outline outline-1 w-72 m-auto outline-company-green text-company-green focus:outline-company-green">Log In</Button>
                </Link>
                <Link href="/register">
                    <Button className="mt-4 outline outline-1 w-72 m-auto bg-company-green text-black ">Create an Account</Button>
                </Link>
            </div>
           
          
            
          </li>
          <li className='p-6 mt-8 justify-start m-auto flex' >
            <AiFillFire className="my-auto mr-4 text-xl text-company-green"/>
            <Link href="/trending" className='hover:text-company-green'>TRENDING</Link>
          </li>
          <li className='p-6  justify-start m-auto flex'>
            <AiOutlineSearch className="my-auto mr-4 text-xl text-company-green" />
            <Link href="/explore" className='hover:text-company-green'>EXPLORE</Link>
          </li>
          <li className='p-6  justify-start m-auto flex'>
            <IoMdPricetag className="my-auto mr-4 text-xl text-company-green" />
            <Link href="/pricing" className='hover:text-company-green'>PRICING</Link>
          </li>
          
          
        </ul>
        </div>

    </header>

    

    )      
}