"use client";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import Link from "next/link";
import { useEffect, useState } from "react";
import { fetchImage } from "../api/ImageHandling/ImageHandling";
import Balance from "./Balance";
import { useAudio } from "./AudioContext";
import axios from "axios";


interface LogoutProps {
    
    username: string; // Add username prop to the interface
}

const Logout: React.FC<LogoutProps> = ({ username }) => {
    const {source, setSource} = useAudio();
    const [imageUrl, setImageUrl] = useState<string>('');
    const [admin, setAdmin] = useState<boolean>(false);
   

    useEffect(() => {
        async function fetchImageData() {
            const imageUrl = await fetchImage();
            
            if (imageUrl) {
                setImageUrl(imageUrl.toString());
            }
        }
        fetchImageData();

        const checkAdmin = async () => {
            try {
              const response = await axios.post("/api/users/checks/isadmin");
          
              setAdmin(response.data.data.is_admin === true);
            } catch (error) {
              router.push("/");
            }
          };
        checkAdmin();          
    }, []); // Include session in dependencies to re-fetch image if session changes

    const router = useRouter();
    const handleLogout = () => {
        
        signOut({
            redirect: true,
            callbackUrl: `${window.location.origin}/`
        });
        setSource(" ")
        router.refresh();
    }

    return (
        <div>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex focus:outline-none hover:text-company-green">
                    <Avatar>
                        <AvatarImage src={imageUrl} />
                        <AvatarFallback>U</AvatarFallback>
                    </Avatar>
                    <div className="justify-end my-auto ml-2"><MdKeyboardArrowDown /></div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mr-24 mt-2 p-2 border border-opacity-10 border-white bg-company-gray text-white text-2xl w-48">
                    
                    <DropdownMenuItem className="flex flex-row text-company-green uppercase text-base">
                        {username}
                    </DropdownMenuItem>
                   <Link href={"/settings/plan"}>
                    <DropdownMenuItem className="flex flex-row text-white -mt-2">
                        <Balance/>
                    </DropdownMenuItem> 
                    </Link>
                    <DropdownMenuSeparator />
                    <hr className="mt-2 opacity-50" />
                    <DropdownMenuItem asChild className="mt-2 hover:text-company-green cursor-pointer">
                        <Link href="/profile">Profile</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild className=" hover:text-company-green cursor-pointer">
                        <Link href="/library">Library</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild className="hover:text-company-green cursor-pointer">
                        <Link href="/dashboard">Dashboard</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild className="hover:text-company-green cursor-pointer">
                        <Link href="/settings">Settings</Link>
                    </DropdownMenuItem>
                    {admin && (
                        <DropdownMenuItem asChild className="hover:text-company-green cursor-pointer">
                            <Link href="/admin">Admin</Link>
                        </DropdownMenuItem>
                    )}
                    <hr className="mt-2 opacity-50" />
                    <DropdownMenuItem onClick={handleLogout} className="mt-2">
                        <button className="flex flex-row my-auto hover:text-company-green">
                            <div className="my-auto pr-2"><MdLogout /></div>Logout
                        </button>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

export default Logout;
