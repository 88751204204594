"use client";

import Link from "next/link";
import Image from 'next/image'
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { fetchImage } from "../api/ImageHandling/ImageHandling";
import Balance from "./Balance";
import axios from "axios";
import { AiFillFire } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { IoMdPricetag } from "react-icons/io";
import { Button } from "@/components/ui/button";
import { IoIosSettings } from "react-icons/io";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { MdLibraryMusic } from "react-icons/md";

export default function HeaderMobileLogout (){
    
    const [nav, setNav] = useState(false);
    const [username, setUsername] = useState('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const router = useRouter();

    const handleNav = () => {
      setNav(!nav);
    };
    useEffect(() => {
      async function fetchImageData() {
          const imageUrl = await fetchImage();
          
          if (imageUrl) {
              setImageUrl(imageUrl.toString());
          }
          const response = await axios.post("/api/users/get/username");
          setUsername(response.data.data.username);
      }
      fetchImageData();

         
  }, []);
  const handleLogout = () => {
          
          signOut({
              redirect: true,
              callbackUrl: `${window.location.origin}/`
          });
          router.refresh();
      }
 

    return(

    <header className=' bg-company-gray text-white fixed top-0 w-screen z-10 ease-in duration-300 mb-20'>
        <div className='flex justify-between items-center gap-4 h-16 mx-auto px-4 text-gray-950'>
        
        <Link href="/">
        <Image
          src="/logo.png"
          width={160}
          height={0}
          alt="COYNED"   
        />
        </Link>
        <div onClick={handleNav}>
          {nav ? <AiOutlineClose size={30}/> : <AiOutlineMenu size={30} />}
        </div>
        </div>

        <div>
       
        <ul className={nav ? 'fixed w-screen h-full border-r border-r-company-gray-hover bg-black ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'} onClick={handleNav}>
          <li className='p-6 border-b border-company-gray-hover justify-start m-auto flex' >
            
            <div className="mr-auto"> 
              <Avatar className="flex h-20 w-20 mr-auto mb-4" >
                <AvatarImage src={imageUrl} />
                <AvatarFallback>U</AvatarFallback>
              </Avatar>
              <p className="text-company-green mr-auto flex justify-end text-xl uppercase mb-2">{username}</p>
              <div className="flex justify-start">
                <Balance/>
              </div>
              
             
            </div> 
            
          </li>
          <li className='p-6 mt-8 justify-start m-auto flex' >
            <AiFillFire className="my-auto mr-4 text-xl text-company-green"/>
            <Link href="/trending" className='hover:text-company-green'>TRENDING</Link>
          </li>
          <li className='p-6  justify-start m-auto flex'>
            <AiOutlineSearch className="my-auto mr-4 text-xl text-company-green" />
            <Link href="/explore" className='hover:text-company-green'>EXPLORE</Link>
          </li>
         
          <li className='p-6  justify-start m-auto flex'>
            <MdLibraryMusic className="my-auto mr-4 text-xl text-company-green" />
            <Link href="/library" className='hover:text-company-green'>LIBRARY</Link>
          </li>
          <li className='p-6  justify-start m-auto flex'>
            <IoIosSettings className="my-auto mr-4 text-xl text-company-green" />
            <Link href="/settings" className='hover:text-company-green'>SETTINGS</Link>
          </li>
          <li className='p-6 justify-center m-auto flex' >
             <div className="mt-8 justify-center flex">
                  <Button onClick={handleLogout} className="w-72 m-auto mt-8 bg-company-green text-company-gray p-2 rounded-full ">Logout </Button>
              </div>
            
          </li>
          
        </ul>
        </div>

    </header>

    

    )      
}