"use client";

import Image from "next/image";
import Link from "next/link";
import { FaInstagram, FaTiktok } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { useAudio } from "./AudioContext";

export default function Footer() {
  const { source } = useAudio();

  return (
    <div className={`w-full m-auto text-white bg-black ${source && source !== " " ? "mb-0 md:mb-20" : ""}`}>
      <div className="pt-8 max-w-[1400px] m-auto grid grid-cols-1 md:grid-cols-5 gap-8 px-4">
        {/* Logo and Text */}
        <div className="col-span-2">
          <div className=" md:w-full">
            <Image className="hidden md:block" src="/logo.png" width={150} height={0} alt="Coyned Logo" />
            {/* Hide this text on mobile, show on md+ */}
            <p className="mt-4 text-white text-opacity-40 text-xs text-justify hidden md:block">
              Coyned is the go-to platform for DJs and producers to explore licensing music that inspires and elevates your sound. Access a vast track library and join a vibrant community of creators. Unleash your creativity and discover endless possibilities with Coyned.
            </p>
          </div>
        </div>

        {/* Product Links */}
        <div className="text-sm text-white text-opacity-40">
          <p className="text-opacity-70 text-company-green mb-4">Product</p>
          <Link href={""}><p className="mb-1">Pricing</p></Link>
          <Link href={""}><p className="mb-1">How it Works</p></Link>
          <Link href={""}><p className="mb-1">Licensing</p></Link>
        </div>

        {/* Support Links */}
        <div className="text-sm text-white text-opacity-40">
          <p className="text-opacity-70 text-company-green mb-4">Support</p>
          <Link href={""}><p className="mb-1">FAQ</p></Link>
          <Link href={""}><p className="mb-1">Contact Us</p></Link>
          <Link href={""}><p className="mb-1">Privacy Policy</p></Link>
          <Link href={""}><p className="mb-1">Terms of Service</p></Link>
        </div>

        {/* Social Media */}
        <div className="text-sm text-white text-opacity-40 m-auto">
          <p className="text-opacity-70 text-company-green mb-4">Social Media</p>
          <p>For recent updates and news follow our social media feeds</p>
          <div className="flex mt-4 text-opacity-70 text-2xl gap-4">
            <Link href={"https://www.instagram.com/coyned.official/"}><FaInstagram /></Link>
            <Link href={"https://www.tiktok.com/@coynedsoftware"}><FaTiktok /></Link>
            <Link href="mailto:info@coyned.net"><IoMail /></Link>
          </div>
        </div>
      </div>

      {/* Footer Bottom Text */}
      <div className="flex m-auto justify-center w-full pb-4 pt-4 text-white text-opacity-40 text-xs">
        © 2025 Coyned Software, UG
      </div>
    </div>
  );
}
