"use client";

import { Button } from "@/components/ui/button";
import { useState, useEffect, useRef } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { useRouter } from "next/navigation";

export default function Searchbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const router = useRouter();

  // Toggle der Suchbar
  const toggleSearchbar = () => {
    setIsOpen((prev) => !prev);
  };

  // Setzt den Fokus auf das Eingabefeld, wenn die Suchbar geöffnet wird
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  // Schließt die Suchbar, wenn man außerhalb klickt
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handhabt die Enter-Taste
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchQuery.trim() !== "") {
      router.push(`/explore?search=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <div className="flex w-full justify-start items-center my-auto mr-2">
      <div
        className={`transition-all duration-300 flex items-center ${
          isOpen ? "w-96" : "w-0"
        } overflow-hidden`}
      >
        {isOpen && (
          <input
            ref={inputRef}
            type="text"
            placeholder="Search..."
            className="w-full px-4 py-2 text-sm border bg-company-gray rounded-lg shadow-sm focus:outline-none mr-4"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        )}
      </div>
      {!isOpen && (
        <Button onClick={toggleSearchbar}>
          <IoSearchSharp />
        </Button>
      )}
    </div>
  );
}
