"use client";

import { use, useState } from "react";
import { usePathname } from "next/navigation";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { MdFeedback } from "react-icons/md";
import axios from "axios";
import { useAudio } from "./AudioContext";

export default function FeedbackButton() {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("");
    const [feedback, setFeedback] = useState("");
    const [loading, setLoading] = useState(false);
    const pathname = usePathname();
    const {source} = useAudio();

    const handleSubmit = async () => {
        if (!type || !feedback) return;
        setLoading(true);
        try {
            await axios.post("/api/feedback/add", { type, feedback, page: pathname });
            setType("");
            setFeedback("");
            setOpen(false); // Schließt den Dialog nach erfolgreichem Absenden
        } catch (error) {
            console.error("Error submitting feedback", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="hidden md:block">
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <button
                        className={`fixed ${source !== " " ? "bottom-24" : "bottom-4"} right-4 bg-company-green text-black hover:bg-company-green/80 transition duration-300 ease-in-out rounded-full p-4 text-xl`}
                        onClick={() => setOpen(true)}
                    >
                        <MdFeedback />
                    </button>
                </DialogTrigger>
                <DialogContent className="bg-company-gray border-white p-6">
                    <DialogTitle className="text-2xl text-company-green">Provide us your feedback!</DialogTitle>

                    <div className="mt-4">
                        <label className="text-white text-sm mb-2 block">Select Feedback Category</label>
                        <Select value={type} onValueChange={setType}>
                            <SelectTrigger className="w-full rounded-lg border bg-company-gray text-white">
                                <SelectValue placeholder="Choose a category" />
                            </SelectTrigger>
                            <SelectContent className="bg-company-gray text-white">
                                <SelectItem value="bug">🐛 Bug Report</SelectItem>
                                <SelectItem value="feature">🚀 Feature Request</SelectItem>
                                <SelectItem value="uiux">🎨 UI/UX Feedback</SelectItem>
                                <SelectItem value="performance">⚡ Performance Issues</SelectItem>
                                <SelectItem value="other">📝 Other</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <label className="text-white text-sm block mt-4">Your Feedback:</label>
                    <div className="text-white -mt-2">
                        <Textarea
                            className="border border-white border-opacity-60 text-gray-900 sm:text-sm rounded-lg focus:outline-none focus:ring-0 block w-full p-2.5 bg-transparent focus:ring-transparent"
                            placeholder="Type your message here."
                            rows={5}
                            value={feedback}
                            onChange={(e) => setFeedback(e.target.value)}
                            required
                        />
                    </div>

                    <div className="flex justify-end mt-4">
                        <Button
                            className="bg-company-green text-black hover:bg-company-green/80 transition duration-300 ease-in-out"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? "Submitting..." : "Submit"}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
